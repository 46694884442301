import outlook from '@/outlook'

const initLanguage = {
  methods: {
    initLanguage () {
      const lang = outlook.language
      if (!this.$i18n.availableLocales.includes(lang)) {
        console.warn(`Flexopus doesn't support ${lang} language. Return back to ${this.$i18n.locale}`)
        return
      }
      this.$i18n.locale = outlook.language
    },
  },
}

export default initLanguage

/* global Office */
import { computed, ref, watch } from 'vue'

class Outlook {
  setAttendeesBroken = null

  useAppointmentSchedule () {
    const start = ref(new Date())
    const end = ref(new Date())
    const recurrence = ref(null)

    let recurrenceInterval

    const getTime = () => {
      Office.context.mailbox.item.start.getAsync(result => {
        if (result.value.getTime() !== start.value.getTime()) {
          start.value = result.value
        }
      })

      Office.context.mailbox.item.end.getAsync(result => {
        if (result.value.getTime() !== end.value.getTime()) {
          end.value = result.value
        }
      })
    }

    getTime()

    if (Office.context.mailbox.item.recurrence) {
      const getRecurrence = () => new Promise((resolve) => Office.context.mailbox.item.recurrence.getAsync(result => {
        resolve(result.value)
      }))

      getRecurrence().then(value => {
        recurrence.value = value
      })

      Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecurrenceChanged, args => {
        recurrence.value = args.recurrence
      })

      watch(recurrence, value => {
        if (value && !recurrenceInterval) {
          recurrenceInterval = setInterval(async () => {
            recurrence.value = await getRecurrence()
          }, 10000)
        } else if (!value && recurrenceInterval) {
          clearInterval(recurrenceInterval)
          recurrenceInterval = undefined
        }
      }, { immediate: true })
    }

    if (Office.context.mailbox.item.addHandlerAsync !== undefined) {
      Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, args => {
        start.value = new Date(args.start)
        end.value = new Date(args.end)
      })
    } else {
      setInterval(() => {
        getTime()
      }, 10000)
    }

    return { start, end, recurrence }
  }

  useRequiredAttendees () {
    const attendees = ref([])

    const get = () => Office.context.mailbox.item.requiredAttendees.getAsync(result => {
      if (JSON.stringify(attendees.value) !== JSON.stringify(result.value)) {
        attendees.value = result.value
      }
    })

    if (Office.context.mailbox.item.addHandlerAsync !== undefined) {
      Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, args => {
        if (args.changedRecipientFields.requiredAttendees) {
          get()
        }
      })
    } else {
      setInterval(() => {
        get()
      }, 10000)
    }

    get()

    return computed(() => attendees.value.map(attendee => attendee.emailAddress))
  }

  recipientsChanged (callback) {
    window.Office.context.ui.addHandlerAsync(
      window.Office.EventType.DialogParentMessageReceived,
      callback,
    )
  }

  messageParent (message) {
    window.Office.context.ui.messageParent(message)
  }

  addAttendee (email) {
    Office.context.mailbox.item.requiredAttendees.addAsync([email])
  }

  removeAttendee (email) {
    Office.context.mailbox.item.requiredAttendees.getAsync(result => {
      const attendees = result.value.filter(a => a.emailAddress !== email)

      if (this.setAttendeesBroken) {
        attendees.splice(attendees.find(a => a.emailAddress !== this.setAttendeesBroken), 1)
      }

      Office.context.mailbox.item.requiredAttendees.setAsync(attendees, () => {
        if (this.setAttendeesBroken !== null) {
          return
        }

        Office.context.mailbox.item.requiredAttendees.getAsync(result => {
          if (result.value.length === attendees.length) {
            this.setAttendeesBroken = false
            return
          }

          Office.context.mailbox.item.organizer.getAsync(organiser => {
            this.setAttendeesBroken = organiser.value.emailAddress
            attendees.splice(attendees.find(a => a.emailAddress !== this.setAttendeesBroken), 1)
            Office.context.mailbox.item.requiredAttendees.setAsync(attendees)
          })
        })
      })
    })
  }

  getSetting (name) {
    return Office.context.roamingSettings.get(name)
  }

  setSetting (name, value) {
    Office.context.roamingSettings.set(name, value)
  }

  saveSettings () {
    return new Promise(resolve => {
      Office.context.roamingSettings.saveAsync(() => resolve())
    })
  }

  displayDialog (url, messageHandler) {
    Office.context.ui.displayDialogAsync(url, result => {
      result.value.addEventHandler(Office.EventType.DialogMessageReceived, event => {
        messageHandler(event.message, result.value)
      })
    })
  }

  get language () {
    const lang = Office.context.displayLanguage.split('-')
    return lang.length > 0 ? lang[0] : 'en'
  }
}

const outlook = new Outlook()
export default outlook

<template>
  <div>
    <label class="flex flex-col">
      <span class="text-neutral-gray-500 text-left text-xs mb-1 pl-2 font-medium">{{ label }}</span>
      <input
        :value="search"
        :placeholder="placeholder"
        :type="type"
        class="input focus:border-microsoft-blue-default hover:border-black transition-all duration-200"
        @input="input"
        @change="update"
      >
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'search',
    event: 'input',
  },
  props: {
    search: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search...',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
  },
  methods: {
    input (e) {
      this.$emit('input', e.target.value)
    },
    update () {
      this.$emit('update')
    },
  },
}
</script>

<style lang="scss" scoped>
.input {
  @apply w-full text-neutral-gray-700
  text-[12px] px-2
  border border-neutral-gray-400 rounded-md
  outline-0 bg-white/60;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;

  &::placeholder {
    opacity: 0.6;
    @apply text-neutral-gray-700;
  }
}
</style>
